<!--
 * @version: 20220211
 * @Author: lcx
 * @Date: 2022-09-01 14:31:06
 * @LastEditors: lcx
 * @LastEditTime: 2022-09-01 17:55:51
 * @Description: description
-->
<template>
  <div class="page-box">
    <div class="notice-details">
      <div class="notice-title">天美云隐私政策</div>
      <div class="time">2022-09-01</div>
      <div class="notice-cont">
        <!-- 详情图  -->
        <div class='goodsDetail'>
          <div>
            广州天美联盟网络科技有限公司（以下简称“天美云”或“我们”）尊重并保护用户隐私，天美云将按照本隐私权规则（以下简称“本规则”）收集、使用及共享您的个人信息。本规则包含了我们收集、存储、保护、使用和共享您的个人信息的条款，我们建议您完整地阅读本规则，以帮助您了解维护自己隐私权的方式。为了使您充分理解本规则，本规则中与您的权益存在或可能存在重大关系的条款，我们已采用粗体字进行标注提示您注意。
            <b>当您同意《天美云服务协议》时，或您访问天美云网站（h5.pay.teamax-rj.com，下同）及其相关网站、天美云移动设备客户端时，或您使用我们提供的任一服务时，即表示您已同意我们按照本规则来合法使用和保护您的个人信息。</b>
          </div>
          <div>
            我们收集、使用您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的（包括支持我们开发新产品或完善已有产品功能，为您和其他用户提供更为优质的服务）。若您向我们提供了本规则中列明的信息，您可使用更多的天美云服务，和（或）享受更便捷的客户服务，和（或）让我们更好地保护您的账户及资金安全，和（或）避免我们无法及时与您取得联系而产生对您不利的影响等。
          </div>
          <div>如何收集个人信息</div>
          <div>
            当您访问天美云网站及其相关网站、天美云移动设备客户端时，或您使用天美云服务时，您会向我们主动提供一些信息，我们也会收集您的一些信息，我们也可能会从天美云关联公司、天美联盟旗下公司和商业合作伙伴获得信息以补充我们自己收集的信息。
          </div>
          <div>我们收集信息的范围主要包括：</div>
          <div> 1、为了遵守法律法规的要求，以及向您提供更个性化、更便捷的服务，我们需要识别您的身份。
            <b>在您注册天美云账户或使用天美云提供的各项服务时，您可能要向我们提供一些个人信息，例如您的姓名、身份证明、地址、电话号码和电子邮件地址、生物特征等信息及相关附加信息（如您所在的省份和城市、邮政编码等）。</b>
          </div>
          <div> 2、如您使用的天美云服务需与您的银行账户或其他支付工具的账户关联方能实现时，
            <b>您需要向我们提供您的银行账户信息或其他支付工具的账户信息。例如，您在开通天美云的快捷支付服务，将您的银行卡与天美云账户绑定时，您需向我们提供您的银行卡信息。</b>
          </div>
          <div> 3、为便于您查询您的交易状态或历史记录，也为了遵守法律法规的规定， <b>我们会保存您使用天美云服务产生的交易信息，并严格按照法律法规的规定对这些信息进行妥善保管。</b>
          </div>
          <div>
            <b>4、为了充分保护您的账户安全，当您访问天美云网站及其相关网站、或天美云移动设备客户端时，或使用天美云提供的服务时，我们可能会记录您操作的相关信息，包括但不限于您的计算机IP地址、设备标识符、硬件型号、操作系统版本、您的位置以及与天美云服务相关的日志信息，</b>
            这些信息可帮助我们更好地识别您的身份以及保护您的账户安全，例如您的账户在可疑操作环境下登录的，天美云系统可能监控到您的账户风险，采取一些措施避免您的资金损失。
          </div>
          <div>
            <b>除上述信息外，我们还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与我们的客户服务团队联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与天美云关联公司和（或）天美联盟旗下公司互动时我们收集的相关信息。与此同时，为提高您使用天美云提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。</b>
          </div>
          <div>如何使用Cookie</div>
          <div>
            为使您获得更轻松的访问体验，您访问天美云网站或使用天美云提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie，Flash
            Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</div>
          <div>
            <b>请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝天美云的Cookie，但这一举动在某些情况下可能会影响您安全访问天美云网站和使用天美云提供的服务。</b>
          </div>
          <div>如何保护和存储个人信息</div>
          <div>
            为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。
          </div>
          <div><b>天美云会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，天美云将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失。</b></div>
          <div><b>您的信息存储于中国的服务器上，为了安全及备份的需要，天美云可能将您的信息和资料储存到天美云关联公司和（或）天美联盟旗下的其他公司的服务器上。</b></div>
          <div>如何使用个人信息</div>
          <div>因收集您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的，为了实现这一目的，我们会把您的信息用于下列用途：</div>
          <div><b>1、向您提供天美云的各项服务及客户服务，并维护、改进这些服务。</b></div>
          <div><b>2、比较信息的准确性，并与第三方进行验证。例如，将您向我们提交的身份信息与身份验证的服务机构进行验证。</b></div>
          <div><b>3、为使您知晓自己使用天美云服务的情况或了解天美云的服务，向您发送服务状态的通知、营销活动及其他商业性电子信息。</b></div>
          <div>
            <b>4、对天美云用户的身份数据、交易信息等进行综合统计、分析或加工，并出于销售、奖励或为了让您拥有更广泛的社交圈的需要而使用、共享或披露；例如我们可能会统计某个时间段注册天美云账户的新用户，对这些新用户提供专享的优惠活动。</b>
          </div>
          <div><b>5、预防或禁止非法的活动。</b></div>
          <div><b>6、经您许可的其他用途。</b></div>
          <div>如何共享个人信息</div>
          <div>我们对您的信息承担保密义务，但我们会在下列情况下将您的信息与第三方共享：</div>
          <div><b>1、获得您的同意或授权。</b></div>
          <div>
            <b>2、为了向您提供或推荐服务、产品，或为了向您提供更完善的服务，或者为了让您拥有更广泛的社交体验，我们会与包括天美云关联公司和天美联盟旗下公司在内的第三方共享您的相关信息。</b>
          </div>
          <div>
            <b>3、某些情况下，只有共享您的信息，才能提供您需要的服务和（或）产品，或处理您与他人的交易纠纷或争议。例如，与您交易的相对方或您本人否认交易为本人操作时，我们可能会将您提交的发货凭证、签收单据，或在天美云系统中的交易信息等信息共享给银行或您的交易相对方，以证明交易的真实存在，维护您的合法权益。</b>
          </div>
          <div><b>4、为了判断您的天美云账户或交易是否安全。</b></div>
          <div>
            <b>5、某些服务和（或）产品由我们的合作伙伴提供或由我们与合作伙伴、供应商共同提供，我们会与其共享提供服务和（或）产品需要的信息。例如，您在天美云网站进行水电煤缴费时，我们必须把您填写的户号和姓名等信息提供给合作的公共事业单位，才能完成缴费。</b>
          </div>
          <div><b>6、我们与第三方进行联合推广活动，我们可能与其共享活动过程中产生的、为完成活动所必要的个人信息，如参加活动的用户数、中奖名单、中奖人联系方式等，以便第三方能及时向您发放奖品。</b></div>
          <div><b>7、为维护天美云及天美云关联公司、天美联盟旗下公司和其他天美云用户的合法权益。</b></div>
          <div>
            <b>8、根据法律规定及合理商业习惯，在我们计划与其他公司合并或被其收购或进行其他资本市场活动（包括但不限于IPO，债券发行）时，以及其他情形下我们需要接受来自其他主体的尽职调查时，我们会把您的信息提供给必要的主体，但我们会通过和这些主体签署保密协议等方式要求其对您的个人信息采取合理的保密措施。</b>
          </div>
          <div>
            <b>9、为了便于您与您的好友进行更加灵活、安全的资金往来，避免因手动输入天美云登录名错误而产生损失，当您的天美云账户所关联的手机号码被保存在他人手机通讯录中时，您的天美云登录名可以被通讯录存有该手机号码的好友查询到。本规则中所称的“天美云账户关联的手机号码”，是指作为天美云登录名的手机号码或天美云账户绑定的手机号码。</b>
          </div>
          <div><b>10、同本部分第9项之目的，他人可以通过您天美云账户关联手机号码查询到对应的天美云登录名。</b></div>
          <div>
            <b>11、您与他人通过天美云服务发生资金往来的，交易相对方可在付款过程中，或付款成功后可查看到您使用的天美云账户的部分信息（包括天美云登录名、天美云账户对应的姓名或昵称、天美云账户头像等，具体以实际可查看信息为准）。</b>
          </div>
          <div><b>12、如您授权第三方向天美云查询、采集您天美云账户相关信息，我们有权在法律法规和您的授权许可范围内向第三方分享您天美云账户的部分信息。</b></div>
          <div><b>13、为了维护和改善我们的服务。</b></div>
          <div><b>14、根据法律法规的规定或有权机关的要求。</b></div>
          <div>如何查询和管理个人信息</div>
          <div>您可随时登录您的天美云账户查询并管理该账户下您的个人信息，如您遇到任何障碍或疑问，可联系天美云客服400-881-6160。</div>
          <div>对第三方责任的声明</div>
          <div>
            请您注意，<b>您的交易相对方、您访问的第三方网站经营者、您使用的第三方服务提供者和由天美云处接受您的个人信息的第三方可能有自己的隐私权保护规则；当您查看第三方创建的网页或使用第三方开发的应用程序时，可能会发现该网页或应用程序放置的Cookie或像素标签。同样，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或标签不受我们的控制，而且它们的使用不受本规则的约束。</b>
          </div>
          <div>
            <b>我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，亦不对这些主体的行为及后果承担任何责任。如果您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。</b>
          </div>
          <div>特别注意事项</div>
          <div><b>我们仅向年满16周岁的人士提供服务，如果您未满16周岁，请您不要向我们提供任何个人信息，也不建议您使用任何天美云提供的服务。</b></div>
          <div>适用范围</div>
          <div>
            <b>本规则属于《天美云服务协议》不可分割的一部分，本规则与《天美云服务协议》的约定不一致的，以本规则为准。除需适用天美云其他单独制订的隐私权规则的服务外，本规则适用于您访问天美云网站及其相关网站、天美云移动设备客户端和天美云提供的任一服务。</b>
          </div>
          <div>如何修订本规则</div>
          <div>
            <b>我们可能随时会对本规则进行变更，由于天美云的用户群过于庞大和分散，因此如发生变更，我们将选择在天美云网站以公告的方式予以公布而不再另行单独通知您，该等变更将在公布时即时生效。若您在本规则变更后继续访问天美云网站及其相关网站、天美云移动设备客户端，或使用天美云提供的任一服务，我们相信这代表您已充分阅读、理解并接受修改后的本规则并受之约束。</b>
          </div>
          <div>联系</div>
          <div>如您对本规则存在任何疑问，或任何相关的投诉、意见，请联系天美云客服800-808-833。</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
</script>
<style lang="less" scoped>
  .page-box {
    min-height: 100vh;
    background-color: #FFFFFF;
  }

  .notice-details {
    padding: 15px;

    .notice-title {
      text-align: center;
      font-size: 18px;
      color: #333333;
    }

    .time {
      text-align: right;
      font-size: 13px;
      color: #999999;
      margin: 15px 0 20px;
    }

    .goodsDetail {
      font-size: 14px;
      text-indent: 20px;
      line-height: 1.8;

      div {
        margin-bottom: 10px;
      }

      b {}
    }
  }
</style>